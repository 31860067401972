import YoutubeVideoComponent from '@pretto/bricks/website/shared/components/YoutubeVideo'

import * as S from '../styles'

const YoutubeVideo = props => (
  <S.BlockLarge>
    <YoutubeVideoComponent {...props} />
  </S.BlockLarge>
)

export default YoutubeVideo
