import PropTypes from 'prop-types'

import * as S from './styles'

const YoutubeVideo = ({ source, width }) => {
  return (
    <S.YoutubeVideo width={width}>
      <S.Container>
        <S.Iframe
          allow="accelerometer;encrypted-media;gyroscope;picture-in-picture;autoplay;"
          allowFullScreen
          frameBorder="0"
          src={source}
        />
      </S.Container>
    </S.YoutubeVideo>
  )
}

YoutubeVideo.propTypes = {
  source: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
}

export default YoutubeVideo
