import styled, { css } from 'styled-components'

export const YoutubeVideo = styled.div`
  margin: 0 auto;
  position: relative;

  ${props =>
    props.width &&
    css`
      max-width: ${props.width}px;
    `}
`

export const Container = styled.div`
  padding-bottom: 56.25%; /* 16:9 */
  ${props =>
    props.width &&
    css`
      max-width: ${props.width}px;
    `}
`

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
